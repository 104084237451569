<template>
  <div class="note-container">
    <div class="search-area">
      <el-form ref="search-form" class="search-form" :model="searchForm" :rules="rules" inline>
        <el-form-item label="系统名称" prop="serviceName">
          <el-input v-model="searchForm.serviceName" clearable></el-input>
        </el-form-item>
        <el-form-item label="接入时间" prop="updateTime">
          <el-date-picker
            v-model="searchForm.updateTime"
            size="small"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="操作人" prop="updateBy">
          <el-input v-model="searchForm.updateBy" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchBtn" :loading="tableLoading">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table-area">
      <div class="operate-btn">
        <el-button type="primary" class="add-btn" icon="el-icon-plus" @click="addBtn">新增接入</el-button>
        <el-button :disabled="tableMultipleSelection.length===0" type="danger" class="add-btn" icon="el-icon-delete" @click="batchDelBtn" :loading="batchDelLoading">批量删除</el-button>
      </div>
      <el-table
        ref="resultTable"
        :data="tableList"
        width="100%"
        tooltip-effect="dark"
        @selection-change="handleTableSelectionChange"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55" align="center" :selectable="handleSelectable" />
        <el-table-column label="序号" width="80">
          <template v-slot="scope">
            {{paginationConfig.pageSize * (paginationConfig.pageNum - 1) + (scope.$index + 1)}}
          </template>
        </el-table-column>
        <el-table-column label="系统图标" prop="serviceImgPath" min-width="80">
          <template v-slot="{row}">
            <el-avatar v-if="row.serviceIconPath" shape="square" size="medium" :src="row.serviceIconPath"></el-avatar>
            <span v-else>未设置</span>
          </template>
        </el-table-column>
        <el-table-column label="背景图片" prop="serviceImgPath" min-width="80">
          <template v-slot="{row}">
            <el-avatar v-if="row.serviceImgPath" shape="square" size="medium" :src="row.serviceImgPath"></el-avatar>
            <span v-else>未设置</span>
          </template>
        </el-table-column>
        <el-table-column label="系统名称" prop="serviceName" min-width="160" show-overflow-tooltip />
<!--        <el-table-column label="跳转路径" prop="servicePath" min-width="160" show-overflow-tooltip />-->
        <el-table-column label="系统描述" prop="description" min-width="120" show-overflow-tooltip />
        <el-table-column label="启用状态" min-width="80">
          <template v-slot="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="1"
              inactive-value="0"
              @change="shiftStatus(scope.row.id,$event)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="updateBy" width="120" />
        <el-table-column label="接入时间" prop="createTime" min-width="180" />
        <el-table-column label="操作" min-width="180">
          <template v-slot="scope">
            <el-button type="text" @click="detailBtn(scope.row.id)">详情</el-button>
            <el-button :disabled="scope.row.status==='1'" type="text" @click="editBtn(scope.row.id)">修改</el-button>
            <el-button :disabled="scope.row.status==='1'" type="text" @click="delBtn(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginationConfig.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="paginationConfig.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="paginationConfig.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { batchNoteDel, editSa, querySaPagination } from '@/service/api/sys-access/system-access'
import { checkTextName } from '@/util/validate/text-validator'

export default {
  name: 'SystemAccessTable',
  data() {
    const checkTitle = (rules, value, callback) => {
      return checkTextName(false, '系统名称', rules, value, callback, 2, 20)
    }
    const checkUpdater = (rules, value, callback) => {
      return checkTextName(false, '发布人', rules, value, callback, 2, 20)
    }
    return {
      searchForm: {
        serviceName: '',
        updateBy: '',
        updateTime: []
      },
      rules: {
        serviceName: [{ required: false, validator: checkTitle, trigger: 'change' }],
        updateBy: [{ required: false, validator: checkUpdater, trigger: 'change' }]
      },
      tableList: [],
      tableMultipleSelection: [],
      tableLoading: false,
      batchDelLoading: false,
      noteTypeList: [],
      paginationConfig: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    /**
     * 查询区域
     */
    // 查询
    searchBtn() {
      this.paginationConfig.pageNum = 1
      this.getTableList()
    },
    // 重置
    resetSearch() {
      this.$refs['search-form'].resetFields()
      this.searchBtn()
    },

    /**
     *  表格区域
     */
    // 新增按钮
    addBtn() {
      this.$emit('changePage', { pageId: 'SystemAccessMng' })
    },
    // 批量删除
    batchDelBtn() {
      if (this.tableMultipleSelection.length === 0) {
        this.$message.warning('请勾选需要删除的数据。')
        return
      }
      this.$confirm('此操作将永久删除已勾选的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchDelLoading = true
        const removeArr = []
        for (const i in this.tableMultipleSelection) {
          removeArr.push(this.tableMultipleSelection[i].id)
        }
        batchNoteDel({ idStr: JSON.stringify(removeArr) }).then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg || '删除成功！')
            this.searchBtn()
          } else {
            this.$message.error(res.data.msg || '删除失败！')
          }
          this.batchDelLoading = false
        })
          .catch((e) => {
            console.log(e)
            this.batchDelLoading = false
          })
      }).catch((e) => {
        console.log(e)
      })
    },
    // 获取表格数据
    getTableList() {
      this.$refs['search-form'].validate(valid => {
        if (valid) {
          this.tableLoading = true
          const params = { ...this.searchForm, ...this.paginationConfig }
          params.startTime = this.searchForm.updateTime && this.searchForm.updateTime.length > 0 ? this.searchForm.updateTime[0] : ''
          params.endTime = this.searchForm.updateTime && this.searchForm.updateTime.length > 0 ? this.searchForm.updateTime[1] : ''
          delete params.updateTime
          delete params.total
          querySaPagination(params).then(res => {
            this.tableLoading = false
            if (res.data.status === 200) {
              this.tableList = res.data.data
              this.paginationConfig.total = res.data.total
            } else {
              this.$message.error(res.data.msg || '服务异常，获取系统接入列表数据失败。')
            }
          }).catch(e => {
            this.tableLoading = false
            console.log(e)
            this.$message.error('服务器错误。')
          })
        }
      })
    },
    // 表格多选
    handleTableSelectionChange(val) {
      this.tableMultipleSelection = JSON.parse(JSON.stringify(val))
    },
    // 表格可选控制
    handleSelectable(row) {
      return row.status !== '1'
    },

    // 系统接入启用/停用
    shiftStatus(id, e) {
      editSa({ id: id, status: e }).then(res => {
        if (res.data.status === 200) {
          this.$message.success(res.data.msg || '状态切换成功！')
        } else {
          this.$message.error(res.data.msg || '状态切换失败！')
          this.getTableList()
        }
      }).catch(e => {
        console.log(e)
        this.getTableList()
      })
    },
    // 详情按钮
    detailBtn(id) {
      this.$emit('changePage', { pageId: 'SystemAccessDetail', pageData: { id: id } })
    },
    // 编辑按钮
    editBtn(id) {
      this.$emit('changePage', { pageId: 'SystemAccessMng', pageData: { id: id } })
    },
    // 删除按钮
    delBtn(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchDelLoading = true
        const removeArr = []
        removeArr.push(id)
        batchNoteDel({ idStr: JSON.stringify(removeArr) }).then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg || '删除成功！')
            this.searchBtn()
          } else {
            this.$message.error(res.data.msg || '删除失败！')
          }
          this.batchDelLoading = false
        })
          .catch((e) => {
            console.log(e)
            this.$message.error('服务器错误。')
            this.batchDelLoading = false
          })
      }).catch((e) => {
        console.log(e)
      })
    },
    /**
     * 分页
     */
    // 页大小切换
    handleSizeChange(val) {
      this.paginationConfig.pageSize = val
      this.getTableList()
    },
    // 当前页切换
    handleCurrentChange(val) {
      this.paginationConfig.pageNum = val
      this.getTableList()
    }

  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/common/search-area.scss";
@import "@/assets/css/common/table-area.scss";
.note-container{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
  overflow-y: auto;
}
</style>
<style lang="scss">
.el-tooltip__popper{
  max-width: 40%;
}
</style>
